//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { draftPage, draftDelete } from '@/api/modular/flowable/draftManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import createForm from '../draftapply/createForm'
export default {
  components: {
    XCard,
    STable,
    createForm
  },
  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '流程名稱',
          dataIndex: 'processName'
        },
        {
          title: '分類編碼',
          dataIndex: 'category'
        },
        {
          title: '分類名稱',
          dataIndex: 'categoryName'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return draftPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      spinningLoading: false,
      flowableCategoryListData: [],
      selectedRowKeys: [],
      selectedRows: [],
      indexPageShow: true,
      createFormShow: false
    }
  },
  created () {
    this.flowableCategoryList()
    if (this.hasPerm('flowableDraft:add') || this.hasPerm('flowableDraft:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 打開表單填寫組件
     */
    openCreateForm (items) {
      if (items.processDefinitionId == null) {
        this.$message.warning('打開失敗：表單項定義processDefinitionId不存在')
      } else {
        this.indexPageShow = false
        this.createFormShow = true
        this.$refs.createForm.open(items)
      }
    },
    /**
     * 正常操作後返回
     */
    closeCreateForm (values) {
      this.draftDelete(values)
      this.indexPageShow = true
      this.createFormShow = false
    },
    /**
     * 普通返回
     */
    rollbackCreateForm () {
      this.indexPageShow = true
      this.createFormShow = false
    },
    /**
     * 刪除草稿，用於表單界面操作後刪除
     */
    draftDelete (id) {
      const params = { id: id }
      this.spinningLoading = true
      draftDelete(params).then((res) => {
        if (res.success) {
          this.$refs.table.refresh()
        } else {
          this.$message.error(res.message)
        }
      }).finally(res => {
        this.spinningLoading = false
      })
    },
    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
